import Carousel from "bootstrap/js/dist/carousel";
import Modal from "bootstrap/js/dist/modal";

// Importa la librería de lightbox si está disponible como módulo
import Lightbox from "bs5-lightbox";

// Inicialización de componentes si es necesario
document.addEventListener("DOMContentLoaded", (event) => {
  // Inicializa Carousel si es necesario hacerlo manualmente
  // ...
  const options = {
    keyboard: true,
    size: "fullscreen",
  };

  // Inicializa todas las instancias de lightbox en la página
  const lightbox = new Lightbox(options);
  lightbox.init();
});
